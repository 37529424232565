import "./hero.css"
import { Component, Fragment } from "react";
import { STATISTIC } from "../properties/statistic.js";
import { DAMAGE_ARRAY, HERO_ARRAY, SUPPORT_ARRAY, TANK_ARRAY } from "../properties/hero.js";
import { useNavigate} from "react-router-dom";
import { SERVER_ARRAY } from "../properties/server.js";

function withNavigate(Component) {
    return function (props) {
      const navigate = useNavigate();
      return <Component {...props} navigate={navigate} />;
    };
}

class Hero extends Component {
    heroes = HERO_ARRAY;
    competData = STATISTIC["compet-" + SERVER_ARRAY[0]]

    openPage = (page) => {
        this.props.navigate(page);
    }

    emphasizeRoleText = (role) => {
        const texts = document.getElementsByClassName("role_text");
        for (const text of texts) {
            text.style.backgroundColor = "#F5F5F5";
            text.style.color = "#0A0A0A";
        }

        const text = document.getElementById("role_text_" + role);
        text.style.backgroundColor = "#0A0A0A";
        text.style.color = "#F5F5F5"
    }
    
    setDisplayRole = (role) => {
        switch (role) {
            case "all": 
                this.heroes = HERO_ARRAY;
                break;
            case "tank": 
                this.heroes = TANK_ARRAY;
                break;
            case "damage": 
                this.heroes = DAMAGE_ARRAY;
                break;
            case "support": 
                this.heroes = SUPPORT_ARRAY;
                break;
            default: 
                console.log("hero.js, setDisplayRole, Unknown role : " + role);
                return ;
        }

        this.emphasizeRoleText(role);
        this.loadRanks();
    }

    setServer = (server) => {
        if (!SERVER_ARRAY.includes(server)) {
            console.log("hero.js, setServer, Unknown server : " + server);  
            return ;
        }     

        document.getElementById("server_text_amer").style.display = "none";
        document.getElementById("server_text_asia").style.display = "none";
        document.getElementById("server_text_eu").style.display = "none";
        document.getElementById("server_text_" + server).style.display = "block";
        this.competData = STATISTIC["compet-" + server];
        this.loadRanks();
    }
    
    loadRanks = () => {
        const pickRateData = STATISTIC["pick_rate"];
        const kdData = STATISTIC["kd"];

        const rankTableBody = document.getElementById("rank_table_body");
        rankTableBody.innerHTML = "";

        let rank = 1;
        for (let hero in this.competData) {
            if (!this.heroes.includes(hero)) {
                continue ;
            }
        
            const bodyDiv = document.createElement("div");
            bodyDiv.className = "rank_table_body_box"
        
            const infoDiv = document.createElement("div");
            infoDiv.className = "rank_table_body_info_box";
        
            const rankText = document.createElement("p");
            rankText.className = "rank_table_body_rank_text";
            rankText.textContent = rank;
        
            const tierImg = document.createElement("img");
            tierImg.className = "rank_table_body_tier_image";
            const tier = (score) => {
                if (score >= 750) {
                    return "s";
                } else if (score >= 440) {
                    return "a";
                } else if (score >= 130) {
                    return "b";
                } else if (score >= 50) { 
                    return "c";
                } else {
                    return "f";
                }
            };
            tierImg.src = process.env.PUBLIC_URL + "/images/tiers/" + tier(this.competData[hero]) + "_tier.svg";
        
            const heroImg = document.createElement("img");
            heroImg.className = "rank_table_body_hero_image";
            heroImg.src = process.env.PUBLIC_URL + "/images/heroes/" + hero + ".webp";
        
            const heroName = document.createElement("p");
            heroName.className = "rank_table_body_hero_name";
            heroName.textContent = hero.replaceAll("_", " ").toUpperCase();
        
            infoDiv.appendChild(rankText);
            infoDiv.appendChild(tierImg);
            infoDiv.appendChild(heroImg);
            infoDiv.appendChild(heroName);
        
            const pickRate = document.createElement("p");
            pickRate.className = "rank_table_body_pick_rate";
            pickRate.textContent = (pickRateData["total"][hero] * 100).toFixed(1) + "%";
        
            const kd = document.createElement("p");
            kd.className = "rank_table_body_kd";
            kd.textContent = kdData[hero] === "-" ? "-" : kdData[hero].toFixed(1);
        
            bodyDiv.appendChild(infoDiv);
            bodyDiv.appendChild(pickRate);
            bodyDiv.appendChild(kd);
        
            rankTableBody.appendChild(bodyDiv);
        
            rank++;
        }
    }

    state = {
        arrowHeroRankingDetermined: "./images/arrow_down.svg",
        arrowHeroStatisticsCollected: "images/arrow_down.svg"
    };

    isHeroRankingDeterminedOpened = false;
    transHeroRankingDetermined = () => {
        if (this.isHeroRankingDeterminedOpened) {
            document.getElementById("text_how_hero_ranking_determined_description").style.display = "none";
            this.setState({arrowHeroRankingDetermined: "./images/arrow_down.svg"})
        } else {
            document.getElementById("text_how_hero_ranking_determined_description").style.display = "block";
            this.setState({arrowHeroRankingDetermined: "./images/arrow_up.svg"})
        }
        this.isHeroRankingDeterminedOpened = !this.isHeroRankingDeterminedOpened;
    }

    isHeroStatisticsCollected = false;
    transHeroStatisticsCollected = () => {
        if (this.isHeroStatisticsCollected) {
            document.getElementById("text_how_hero_statistics_collected_description").style.display = "none";
            this.setState({arrowHeroStatisticsCollected: "./images/arrow_down.svg"})
        } else {
            document.getElementById("text_how_hero_statistics_collected_description").style.display = "block";
            this.setState({arrowHeroStatisticsCollected: "./images/arrow_up.svg"})
        }
        this.isHeroStatisticsCollected = !this.isHeroStatisticsCollected;
    }

    componentDidMount() {
        this.loadRanks();
    }

    render() {
        return (
            <Fragment>
                <div id="title">
                    <p id="title_text" onClick={() => this.openPage("/hero")}>WatchOver.pro</p>
                </div>

                <div id="nav">
                    <div id="nav_box">
                        <p id="nav_text_hero" className="nav_text" onClick={() => this.openPage("/hero")}>Hero</p>
                        <p id="nav_text_recommandation" className="nav_text" onClick={() => this.openPage("/recommandation")}>Recommandation</p>
                    </div>
                </div>

                <div id="content_section">
                    <div id="setting_section">
                        <div id="role_section">
                            <p id="role_text_all" className="role_text" onClick={ () => this.setDisplayRole("all") }>ALL</p>
                            <p id="role_text_tank" className="role_text" onClick={ () => this.setDisplayRole("tank") }>TANK</p>
                            <p id="role_text_damage" className="role_text" onClick={ () => this.setDisplayRole("damage") }>DAMAGE</p>
                            <p id="role_text_support" className="role_text" onClick={ () => this.setDisplayRole("support") }>SUPPORT</p>
                        </div>

                        <p id="server_text_amer" className="server_text" onClick={ () => this.setServer("asia") }>🌐 AMER</p>
                        <p id="server_text_asia" className="server_text" onClick={ () => this.setServer("eu") }>🌐 ASIA</p>
                        <p id="server_text_eu" className="server_text" onClick={ () => this.setServer("amer") }>🌐 EU</p>
                    </div>
                    
                    <div id="rank_table_head">
                        <p id="rank_table_head_text_info">Ranking</p>
                        <p id="rank_table_head_text_pick_rate">Pick Rate</p>
                        <p id="rank_table_head_text_kd">K/D</p>
                    </div>

                    <div id="rank_table_body"/>
                </div>

                <div id="page_information_section">
                    <div id="how_hero_ranking_determined_section">
                        <div className="question" onClick={this.transHeroRankingDetermined}>
                            <img id="arrow_how_hero_ranking_determined" className="arrow" src={this.state.arrowHeroRankingDetermined} alt=""></img>
                            <p id="text_how_hero_ranking_determined" className="question_text">How are the hero rankings determined?</p>
                        </div>

                        <p id="text_how_hero_ranking_determined_description" className="answer_text">The hero rankings are determined based on the ranking page. The heroes played by top-ranked players are scored according to their 'most played' rankings. These scores reflect trends in score changes over time, allowing for quick adaptation to balance patches.</p>
                    </div>

                    <div id="how_hero_statistics_collected_section">
                        <div className="question" onClick={this.transHeroStatisticsCollected}>
                            <img id="arrow_how_hero_statistics_collected" className="arrow" src={this.state.arrowHeroStatisticsCollected}  alt=""></img>
                            <p id="text_how_hero_statistics_collected" className="question_text">How are the hero statistics collected?</p>
                        </div>

                        <p id="text_how_hero_statistics_collected_description" className="answer_text">We collect match data from professional Overwatch players. We have collected data from the following matches.<br /><br />
                        - OWCS KOREA STAGE2 PLAYOFFS DAY1 MATCH1<br />
                        - OWCS KOREA STAGE2 PLAYOFFS DAY1 MATCH2<br />
                        - OWCS KOREA STAGE2 PLAYOFFS DAY2 MATCH1<br />
                        - OWCS KOREA STAGE2 GRANDFINALS</p>
                    </div>

                    <p id="site_description_text">
                    Contact : contact.watchover.pro@gmail.com | We value the rights of others. If there is any issue, please contact us. <br />
                    &copy; 2024 watchover.pro. All rights reserved.
                    </p>
                </div>
            </Fragment>
        );
    }
}

export default withNavigate(Hero);